import React from "react";

export const FRGX = () => {

    const bgGradient = {
        backgroundImage: `url('/mainPage/ProjectList/frgx/bg.png')`,
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
      };

    return (
        <div style={bgGradient} className="relative flex justify-between h-[420px] ghh w-full max-w-[600px] rounded-[30px] p-10 sm:flex-col-reverse sm:h-full sm:p-0 sm:px-4 sm:pb-12 sm:rounded-3xl sm:min-w-[311px] sm:min-h-[590px] relative overflow-hidden">
            <div className="flex flex-col justify-end space-y-[68px]">
            <img className="absolute h-[530px] right-[-100px] top-1/2 -translate-y-1/2 sm:h-[350px] sm:top-0 sm:-translate-y-0 sm:left-1/2 sm:-translate-x-1/2  " src="/mainPage/ProjectList/frgx/blackStar.png"/>
                <div className="flex flex-col space-y-3 sm:items-center ">
                    <span className="text-[32px] font-bold text-white poppins leading-none sm:text-xl sm:text-center">FRGX Token </span>
                    <span className="text-white-800 font-ligth sm:text-sm sm:text-center max-w-[240px]">MEO WORLD is a mobile game with an NFT battle pass and a P2E coin. Collect Cougar.</span>
                </div>
                <a target="_blank" href="https://frgx.finance/" className="bg-[#1A1A1A] rounded-2xl px-5 py-3 w-fit sm:w-full">
                    <span className="text-white font-semibold">Explore FRGX Token</span>
                </a>
            </div>
           
        </div>
    )
}