import React, { useState } from "react";

export const ProfileDetails = () => {
    const inputList = [
        {
            title: 'Username',
            placeholder: 'Enter username',
        },
        {
            title: 'Bio',
            placeholder: 'Tell the world your story',
        },
        {
            title: 'Email Address',
            placeholder: 'Email',
        },
    ]

  return (
    <div className="flex flex-col space-y-[60px] sm:space-y-11 ">
        <span className="text-[40px] poppins font-medium leading-none sm:text-2xl">Profile details</span>
        <div className="flex justify-between items-start sm:flex-col sm:w-full sm:space-y-8 ">
            <div className="flex flex-col space-y-8 sm:w-full">
                {inputList?.map((item, itemIndex) => {
                    return (
                    <div className="flex flex-col space-y-6 sm:w-full sm:space-y-4" key={itemIndex}>
                        <span className="text-xl text-white poppins font-medium sm:text-lg">{item?.title}</span>
                        <input placeholder={item?.placeholder} className="w-[312px] h-[48px] outline-none rounded-2xl bg-[#171819] border-[1px] border-white-50 px-5 placeholder-white-200 sm:w-full"></input>
                    </div>
                    )
                })}
                <button className="bg-[#1F86FF] rounded-2xl h-[48px] w-[79px] sm:hidden">
                    <span className="text-white font-medium">Save</span>
                </button>
            </div>
            <div className="flex ">
                <span className="text-xl poppins text-white sm:text-lg">Profile Image</span>
            </div>
            <div className="flex ">
                <span className="text-xl poppins text-white sm:text-lg">Profile Banner</span>
            </div>
            <button className="flex items-center justify-center hidden sm:flex bg-[#1F86FF] rounded-2xl h-[48px] w-full">
                <span className="text-white font-medium">Save</span>
            </button>
        </div>
    </div>
  )
}