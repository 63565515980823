import React, { useState } from "react";
import { ConnectWallet } from "../../features/settings/connectWallet";
import { ProfileDetails } from "../../features/settings/profileDetails";
import { SocialMedia } from "../../features/settings/socialMedia";
import { BaseLayout } from "../../layouts/BaseLayout";

export const Settings = () => {
    const menuItems = [
        {
            title: 'Profile Settings',
            img: 'profile',
            desc : [
                {
                    content: <ProfileDetails /> 
                }
            ]
        },
        {
            title: 'Social Media',
            img: 'social',
            desc : [
                {
                    content: <SocialMedia />
                }
            ]
        },
        {
            title: 'Connect Wallet',
            img: 'connect',
            desc : [
                {
                    content: <ConnectWallet />
                }
            ]
        }
    ]

    const [currentTab, setCurrentTab] = useState(0);

  return (
    <BaseLayout parentStyle="!flex-row !items-start !justify-start pt-[60px] sm:space-y-6 max-w-[1224px] space-x-6 sm:space-x-0 sm:!flex-col sm:max-w-full sm:pt-7">
            <div className="flex flex-col  sm:flex-row sm:overflow-auto sm:max-w-full sm:space-x-3 ">
                {menuItems?.map((item, itemIndex) => {
                    const isActive = itemIndex === currentTab;
                        return (
                        <button onClick={() => {setCurrentTab(itemIndex)}} className={`flex items-center justify-start space-x-4 pl-5 h-[56px] w-[288px] sm:h-10 sm:min-w-[190px] sm:w-full ${ isActive ? 'bg-[#1C1D1E]' : ''} rounded-2xl sm:rounded-3xl sm:border-[1px] sm:border-white-100 sm:justify-center sm:pl-0`}>
                            <img className="" src={`/settings/menu/${item?.img}.svg`} />
                            <span className={`poppins ${isActive ? "text-white" : "text-white-600" }`}>{item?.title}</span>
                        </button>
                        )
                    })}
                    
            </div>
            <div className="border-[1px] border-white-100 h-[450px] sm:hidden" />
            {menuItems[currentTab].desc.map((item, itemIndex) => {
                    return (
                        <div className="pl-20 w-full sm:pl-0">
                            {item?.content}
                        </div>
                        )
                })}
    </BaseLayout>
  )
}