import React from "react";
import { Link } from "react-router-dom";

export const ClixGame = () => {

    const bgClixWindow = {
        backgroundImage: `url('/mainPage/ProjectList/clixGame/bg.png')`,
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
      };
      
    return (
        <div style={bgClixWindow} className="relative max-w-[600px] flex justify-between h-[420px] bg-[#0F1011] w-full rounded-[30px] sm:flex-col sm:h-full sm:rounded-3xl sm:min-w-[311px] sm:min-h-[590px] sm:overflow-hidden">
            <div className="flex flex-col justify-end space-y-[30px] p-10  sm:p-0 sm:px-4 sm:pt-12">
            <div className="flex flex-col sm:items-center">
                <span className="text-[32px] font-bold text-white poppins sm:text-xl sm:text-center">CLIX GAME</span>
                <span className="text-white-800 font-ligth sm:text-sm sm:text-center sm:max-w-[279px] ">CLIX is a mobile game where you can earn tokens by tapping. Get referral rewards, activate boosts and autoclickers, open lootboxes and get rewards in FRGX!</span>
            </div>
            <Link to="/projects/clixgame" className="bg-white rounded-2xl px-5 py-3 w-fit sm:w-full">
                <span className="text-[#EAA812] font-semibold">Explore CLIX</span>
            </Link>
            </div>
            <img className="sm:absolute sm:left-1/2 sm:-translate-x-1/2 sm:-bottom-[20%]" src="/mainPage/ProjectList/clixGame/phone.png"/>
        </div>
    )
}