import React, { useMemo, useState } from 'react';
import { Modal } from '../../Modal';
import { setCookie } from 'nookies';
import { useNavigate } from 'react-router-dom';
import { AuthRepository } from '../../../connectors/repositories/auth';
import { callNotification } from '../../../helpers/notification';

export const LoginModal = ({ onClose, openedModal }) => {
  const navigate = useNavigate();
  const [emailValue, setEmailValue] = useState('');
  const [codeValue, setCodeValue] = useState('');
  const [sendedEmail, setSendedEmail] = useState(false);

  const sendEmail = async (emailValue) => {
    try {
      const result = await AuthRepository.getVerifyCode({ email: emailValue });
      console.log(result);
      if (result.statusCode === 200) {
        callNotification({ message: result?.message });
        setSendedEmail(true);
      }
    } catch (e) {
      callNotification({ type: 'error', message: 'email should not be empty' });
    }
   
  }

  const sendVerifyCode = async (emailValue, codeValue) => {
    try {
      const result = await AuthRepository.sendVerifyCode({ email: emailValue, code: codeValue });
      console.log(result);
      if (result.statusCode === 200) {
        callNotification({ message: result?.message });
        setCookie(null, 'authTokenFrgxSpace', result?.data, {
          maxAge: 30 * 24 * 60 * 60,
          path: '/',
        });

        navigate("/");
      }
    } catch (e) {
      if (e.message) {
        callNotification({ type: 'error', message: 'code should not be empty' });
      }
      console.log(e);
    }
   
  }

  const content = useMemo(() => {
    if (sendedEmail) {
      return {
        title: 'Verify Code',
        input: {
          type: 'text',
          valueInput: codeValue,
          onChangeInput: (e) => setCodeValue(e.target.value),
          text: 'Your code',
          placeholder: 'Enter your code'
        },
        button: {
          text: 'Next',
          func: () => sendVerifyCode(emailValue, codeValue),
        },
      }
    } else {
      return {
        title: 'Login',
        input: {
          type: 'email',
          valueInput: emailValue,
          onChangeInput: (e) => setEmailValue(e.target.value),
          text: 'Login to FRGX Space',
          placeholder: 'Enter your email'
        },
        button: {
          text: 'Login',
          func: () => sendEmail(emailValue),
        },
      }
    }
  }, [emailValue, codeValue, sendedEmail])

  const styleBg = {
    backgroundImage: 'url(/main/loginModal/bg.png)',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'contain',
  }

  return (
    <Modal isOpened={openedModal} onClose={onClose} isDisableOnClose>
      <div style={styleBg} className="relative flex items-end p-6 pb-[48px] bg-[#0D0E0F] rounded-[30px] w-[428px] h-[473px]">
        <button onClick={() => onClose()} className='absolute top-6 right-6 bg-black-300 flex items-center justify-center h-[48px] w-[48px] rounded-[16px] border border-white-50'>
          <img src="/icons/close.svg" alt="" />
        </button>
        <div className="flex flex-col">
          {/* <span className='poppins text-2xl font-semibold text-white mb-10'>Login to FRGX Space</span>*/}
          <span className='poppins text-2xl font-semibold text-white mb-10'>{content?.input?.text}</span>
          <div className="flex items-center space-x-2.5 mb-6">
            <input placeholder={content?.input?.placeholder} type={content?.input?.type} onChange={(e) => content?.input?.onChangeInput(e)} value={content?.input.valueInput} className='py-1 px-5 flex-1 h-[56px] rounded-[16px] bg-[#1C1D1E] border border-white-50 placeholder:text-white-300 text-white focus:outline-none' />
            <button onClick={() => content?.button?.func()} className='flex items-center justify-center p-3 rounded-[16px] bg-[#1F86FF]'>
              <img src="/icons/arrow_long_right.svg" alt="" />
            </button>
          </div>
          <span className='text-xs text-white-600 font-light leading-[17px]'>
            By continuing you agree to our Terms & Privacy&nbsp;Policy and Privy's&nbsp;Terms.
          </span>
        </div>
      </div>
    </Modal>
  );
};
