import React, { useState } from "react";

export const ConnectWallet = () => {

  const walletsList = [
    {
      img: 'TW',
      title: 'Trust Wallet',
      desc: 'DApp in app',
    },
    {
      img: 'TP',
      title: 'TokenPocket',
      desc: 'DApp in app',
    },
    {
      img: 'MT',
      title: 'MetaMask',
      desc: 'Desktop / DApp in app',
    },
    {
      img: 'CW',
      title: 'WalletConnect',
      desc: 'Any wallet and browser',
    },
  ]
  return (
    <div className="flex flex-col space-y-[60px] sm:space-y-11 ">
      <span className="text-[40px] poppins font-medium leading-none sm:text-2xl">Connect your wallet</span>
      <div className="flex flex-col space-y-3">
        {walletsList?.map((item, itemIndex) => {
          return (
            <div className="flex rounded-2xl bg-[#171819] border-[1px] border-white-50 h-[68px] w-[392px] p-2 space-x-6 sm:w-full" key={itemIndex}>
              <img src={`/settings/connectWallet/${item?.img}.svg`} />
              <div className="flex flex-col ">
                <span className="poppins text-lg text-white font-medium">{item?.title}</span>
                <span className="text-sm font-light text-white-600">{item?.desc}</span>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  )
}


      
