import React, { useMemo } from "react";
import { MeoWorld } from "./meoWorld";
import { ClixGame } from "./clixGame";
import { Qornex } from "./qornex";
import { FRGX } from "./frgx";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { isMobile } from 'react-device-detect';

export const ProjectList = () => {

  function SampleNextArrow(props) {
    const { className, style, onClick } = props;
    return (
      <div
        className={className}
        style={{ ...style, position: "absolute", right: !isMobile ? "50px" : "10px", zIndex: '11111' }}
        onClick={onClick}
      />
    );
  }

  const settings = {
    className: "center",
    centerMode: !isMobile,
    dots: true,
    infinite: true,
    speed: 1000,
    slidesToShow: isMobile ? 1 : 2,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
    nextArrow: <SampleNextArrow />,

  };

  const renderContent = useMemo(() => {
    return (
      <div className="flex flex-col space-y-[60px] max-w-full sm:pl-0 sm:w-full sm:space-y-6 sm:max-w-full">
      <span className="text-[40px] poppins font-medium sm:text-2xl sm:text-center ml-[60px] sm:ml-0">Projects on the <br className="hidden sm:flex"/> FRGX Space</span>
      <div className="flex space-x-6 sm:space-x-3 !w-full sm:w-full ">
        <div className="!w-full !h-full">
          <Slider  {...settings} className="!w-full ">
            <MeoWorld />
            <ClixGame />
            <Qornex />
            <FRGX />
          </Slider>
        </div>
      </div>
    </div>
    )
  }, [isMobile, settings])

  return renderContent
}