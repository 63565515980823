import React, { useState } from "react";

export const SocialMedia = () => {
    const inputList = [
        {
            title: 'Twitter',
            placeholder: 'Your Twitter',
        },
        {
            title: 'Discord',
            placeholder: 'Your Discord',
        },
        {
            title: 'Instagram',
            placeholder: 'Your Instagram',
        },
        {
            title: 'Telegram',
            placeholder: 'Your Telegram',
        },
    ]
  return (
    <div className="flex flex-col space-y-[60px] sm:space-y-11 ">
        <span className="text-[40px] poppins font-medium leading-none sm:text-2xl">Social Media</span>
            <div className="flex flex-col space-y-8 sm:w-full">
                {inputList?.map((item, itemIndex) => {
                    return (
                    <div className="flex flex-col space-y-6 sm:w-full sm:space-y-4" key={itemIndex}>
                        <span className="text-xl text-white poppins font-medium sm:text-lg">{item?.title}</span>
                        <input placeholder={item?.placeholder} className="w-[312px] h-[48px] rounded-2xl bg-[#171819] border-[1px] border-white-50 px-5 placeholder-white-200 sm:w-full"></input>
                    </div>
                    )
                })}
                <button className="bg-[#1F86FF] rounded-2xl h-[48px] w-[79px] sm:w-full">
                    <span className="text-white font-medium">Save</span>
                </button>
            </div>

    </div>
  )
}