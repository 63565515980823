import React from "react";
import { Link } from "react-router-dom";

export const MeoWorld = () => {
    return (
      <div className="flex justify-between h-[420px] bg-[#6640FF] w-full max-w-[600px] rounded-[30px] p-10 sm:flex-col-reverse sm:h-full sm:p-0 sm:px-4 sm:pb-12 sm:rounded-3xl sm:min-w-[311px] sm:min-h-[590px] relative px-10">
        <div className="flex flex-col justify-end space-y-[30px]">
          <div className="flex flex-col space-y-3 sm:items-center ">
            <span className="text-[32px] font-bold text-white poppins leading-none sm:text-xl sm:text-center">MEO WORLD <br className="sm:hidden"/> P2E Game</span>
            <span className="text-white-800 font-ligth sm:text-sm sm:text-center sm:max-w-[279px]">MEO WORLD is a mobile game with an NFT battle pass and a P2E coin. Collect Cougars, upgrade them, compete with other players, and earn crypto!</span>
          </div>
          <Link to={`/projects/meoworld`} className="bg-white rounded-2xl px-5 py-3 w-fit sm:w-full">
            <span className="text-[#5334CC] font-semibold">Explore MEO World</span>
          </Link>
        </div>
        <img className="sm:absolute sm:top-0 sm:left-1/2  sm:-translate-x-1/2" src="/mainPage/ProjectList/meoWorld/meoWorld.png"/>
      </div>
    )
}