import React from "react";

export const Qornex = () => {
    return (
        <div className="flex  qornex-banner-bg justify-between h-[420px]  w-full max-w-[600px] rounded-[30px] p-10 sm:flex-col-reverse sm:h-full sm:p-0 sm:px-4 sm:pb-12 sm:rounded-3xl sm:min-w-[311px] sm:min-h-[590px] relative">
            <div className="flex flex-col justify-end space-y-[68px] z-[1]">
                <img className="absolute top-0 left-[150px] max-w-[158px] sm:max-w-[60px] sm:top-[20px] sm:left-[30px]" src="/mainPage/ProjectList/qornex/topToken.png"/>
                <div className="flex flex-col space-y-3 sm:items-center ">
                    <span className="text-[32px] font-bold text-white poppins leading-none sm:text-xl sm:text-center">Qornex</span>
                    <span className="text-white-800 font-ligth sm:text-sm sm:text-center max-w-[240px]">MEO WORLD is a mobile game with an NFT battle pass and a P2E coin. Collect Cougars</span>
                </div>
                <a target="_blank" href="https://qornex.com/" className="bg-[#6850F3] rounded-2xl px-5 py-3 w-fit sm:w-full z-[11]">
                    <span className="text-white font-semibold">Explore Qornex</span>
                </a>
                <img className="absolute bottom-0 sm:hidden" src="/mainPage/ProjectList/qornex/botToken.png"/>
                <img className="hidden sm:flex absolute right-0 top-[60px]" src="/mainPage/ProjectList/qornex/rightTokenMob.png"/>
            </div>
        </div>
    )
}