export const projectsList = [
    {
        name: 'meoworld',
        key: 'meoworld',
        info: {
                title: 'Meo World',
                desc: "MEO WORLD is a mobile game that includes an NFT battle pass and a P2E coin, which is deployed in both the blockchain and the Game as in-game currency. In a galaxy far away, cougars developed advanced technology and created Millennium Games to train themselves and become more and more advanced. Each cougar is unique, as it comes with a distinctive set of characteristics. Collect them, upgrade them, compete with other players, and earn crypto!",
                img: '',
                backgroundImage: '/projects/meoWorld/bg.png',
                backgroundImageMob: '/projects/meoWorld/bgMob.png',
                avatarImage: '/projects/meoWorld/ava.png',
                socials: [
                    {
                        key: 'telegram',
                        href: 'https://t.me/meoworldgame',
                    },
                    {
                        key: 'youtube',
                        href: 'https://www.youtube.com/@meoworldgame',
                    },
                    {
                        key: 'twitter',
                        href: 'https://twitter.com/meoworldgame',
                    },
                    {
                        key: 'discord',
                        href: 'https://t.me/meoworldgame/180',
                    },
                ],
        },
        primaryButton: {
            title: 'Explore MEO World',
            bg: 'bg-[#7355F0]',
            href: '',
        },
       

    },
    {
        name: 'clixgame',
        key: 'clixgame',
        info: {
                title: 'Clix Game',
                desc: "The DeFi market is experiencing significant growth. While it's still maturing, there's great opportunity for new products to gain a massive audience quickly.Get early access to decentralized finance with lightning fast transactions while scaling up with massive audience  experiencing significant growth. While it's still maturing, there's great opportunity for new products to gain a massive audience",
                img: '',
                backgroundImage: '/projects/clixGame/bg.png',
                backgroundImageMob: '/projects/clixGame/bgMob.png',
                avatarImage: '/projects/clixGame/ava.png',
                socials: [
                    {
                        key: 'telegram',
                        href: '',
                    },
                    {
                        key: 'youtube',
                        href: '',
                    },
                ],
            },
        primaryButton: {
            title: 'Start Clicking',
            bg: 'bg-[#F58917]',
            href: '',
        }
    },
]
    
    