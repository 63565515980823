import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { MainPage } from './pages/main';
import { ProfilePage } from './pages/profile';
import { ProjectPage } from './pages/project';
import { Settings } from './pages/settings';


export const RoutesFind = () => {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<MainPage />} />
        <Route path="/profile" element={<ProfilePage />} />
        <Route path="/projects/:id" element={<ProjectPage />}  />
        <Route path="/settings" element={<Settings />} />
      </Routes>
    </Router>
  );
};
