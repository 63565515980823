import React, { useMemo } from "react";
import { BaseLayout } from "../../layouts/BaseLayout";
import { projectsList } from "./project";
import { useParams } from "react-router-dom";


export const ProjectPage = () => {

  const {id} = useParams();

  const currentProject = projectsList.find((item) => 
    item?.key === id
  );

  const checkSocial = (type) => {
    switch (type) {
      case 'telegram':
        return (
          <img src="/icons/project/socials/telegram.svg"/>
        );
      case 'youtube':
        return (
          <img src="/icons/project/socials/youtube.svg"/>
        );
      case 'instagram':
        return (
          <img src="/icons/project/socials/instagram.svg"/>
        );
      case 'medium':
        return (
          <img src="/icons/project/socials/medium.svg"/>
        );
      case 'gitbook':
        return (
          <img src="/icons/project/socials/gitbook.svg"/>
        );
      case 'discord':
        return (
          <img src="/icons/project/socials/discord.svg"/>
        );
      case 'twitter':
        return (
          <img src="/icons/project/socials/twitter.svg"/>
        );  
      default:
        return null;
    }
  };
  

  const renderContent = useMemo(() => {
      return (
        <>
              <div className="relative w-full">
                <div className="relative">
                  <img className="rounded-[30px] sm:hidden" src={currentProject?.info?.backgroundImage}/>
                  <img className="rounded-[30px] w-full hidden sm:flex" src={currentProject?.info?.backgroundImageMob}/>
                    <div className="absolute flex items-center justify-center px-5 py-3 space-x-6 absolute right-3 bottom-5 bg-black-300 border-[1px] border-white-50 rounded-full backdrop-blur-mini sm:bottom-3 sm:rounded-2xl  sm:px-2 sm:py-2 sm:space-x-3">
                      {currentProject?.info?.socials?.map((item, itemIndex) => {
                        return (
                        <a className="sm:w-4 sm:h-4" target="blank" href={item?.href}>
                          {checkSocial(item?.key)}
                        </a>
                        )
                      })}
                    </div>
                </div>
                <div className="absolute bottom-[-50px] rounded-[30px] bg-black-300 border-[1px] border-white-50 left-[30px] p-2.5 rounded-[30px] z-10 h-[152px] w-[152px] sm:h-[140px] sm:w-[140px] sm:left-[15px] sm:bottom-[-30px]">
                  <div className=" w-full h-full rounded-[20px]">
                    <img src={currentProject?.info?.avatarImage}/>
                  </div>
                </div>
              </div>

              <div className="flex justify-between w-full sm:flex-col sm:space-y-6 sm:pt-[25px]">
                <div className="flex flex-col max-w-[600px] w-full space-y-[30px] sm:space-y-6 ">
                  <div className="">
                    <span className="text-[32px] poppins sm:text-2xl">{currentProject?.info?.title}</span>
                  </div>
                  <span className="text-white-600 font-light sm:text-sm">
                    {currentProject?.info?.desc}
                  </span>
                    <button className={`${currentProject?.primaryButton?.bg} rounded-2xl py-4 px-5 w-fit sm:w-full`}>
                      <span className="text-white font-semibold sm:text-sm">{currentProject?.primaryButton?.title}</span>
                    </button>
                </div>

                {/* <div className="flex flex-col bg-[#1C1D1E] border border-white-50 p-6 space-y-3 h-fit min-w-[253px] rounded-[20px] sm:flex-row sm:items-center sm:justify-between sm:space-y-0 sm:p-4">
                  <span className="text-white-400 font-light sm:text-sm">Followers</span>
                  <div className="flex space-x-3">
                    <img className="sm:w-6 sm:h-6" src="/icons/project/followerIcon.svg"/>
                    <span className="text-[32px] font-medium poppins sm:text-2xl">7,000</span>
                  </div>
                </div> */}
              </div>
            </>
      )
  },[currentProject] )

  return (
    <>
      <BaseLayout parentStyle='space-y-[70px] sm:space-y-6 max-w-[1224px] w-full pt-[30px]'>
        {renderContent}
      </BaseLayout>
    </>
    
  )
}